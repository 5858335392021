import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { getFunctions, httpsCallable } from "firebase/functions";
import useScreenSize from "../hooks/useScreenSize";
import {
  cacheAdminUsers,
  getCachedAdminUsers,
  clearAdminUsersCache,
  isAdminUsersCacheValid,
  setAdminUsersCacheTimestamp,
  initializeSecureStorage,
} from "../utils/secureStorage";
import emailjs from "@emailjs/browser";
import { Checkbox } from "primereact/checkbox";
import { debounce } from "lodash";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../constants/messages";
import { Paginator } from "primereact/paginator";
import { useAuth } from "./AuthContext";
import { exponentialBackoff } from '../utils/retryUtils';
import useDisableBackgroundScroll from '../hooks/useDisableBackgroundScroll';

const AdminSettings = () => {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [editingUser, setEditingUser] = useState("");
  const toast = useRef(null);
  const functions = getFunctions();
  const isMobile = useScreenSize();
  const [showInviteWorkerDialog, setShowInviteWorkerDialog] = useState(false);
  const [inviteWorkerEmail, setInviteWorkerEmail] = useState("");
  const [inviteWorkerName, setInviteWorkerName] = useState("");
  const [isInvitingAdmin, setIsInvitingAdmin] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [componentLoading, setComponentLoading] = useState(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_INIT);
  }, []);

  const fetchUsers = useCallback(async () => {
    if (!user || !user.isAdmin) {
      setUsers([]);
      setTotalUsers(0);
      setComponentLoading(false);
      return;
    }

    setComponentLoading(true);
    try {
      if (isAdminUsersCacheValid()) {
        const cachedData = getCachedAdminUsers();
        if (cachedData && cachedData.page === page && cachedData.pageSize === pageSize) {
          setUsers(cachedData.users);
          setTotalUsers(cachedData.totalCount);
          setComponentLoading(false);
          return;
        }
      }

      const listAdminUsers = httpsCallable(functions, "listAdminUsers");
      const result = await exponentialBackoff(() => listAdminUsers({ page, pageSize }));

      if (!result.data || !Array.isArray(result.data.users)) {
        throw new Error("Invalid response from server");
      }

      const newData = {
        users: result.data.users,
        totalCount: result.data.totalCount,
        page: page,
        pageSize: pageSize,
      };

      setUsers(newData.users);
      setTotalUsers(newData.totalCount);
      cacheAdminUsers(newData);
      setAdminUsersCacheTimestamp();
    } catch (error) {
      console.error("Error fetching admin users:", error);
      let errorMessage = "Failed to fetch admin users";
      if (error.code === "unauthenticated") {
        errorMessage = "You must be logged in to view admin users";
      } else if (error.code === "permission-denied") {
        errorMessage = "You don't have permission to view admin users";
      } else if (error.details) {
        errorMessage = `Error: ${error.details}`;
      }
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
      });

      const cachedData = getCachedAdminUsers();
      if (cachedData && Array.isArray(cachedData.users)) {
        setUsers(cachedData.users);
        setTotalUsers(cachedData.totalCount);
        toast.current.show({
          severity: "info",
          summary: "Using Cached Data",
          detail: "Displaying previously cached user data.",
        });
      }
    } finally {
      setComponentLoading(false);
    }
  }, [user, functions, page, pageSize]);

  useEffect(() => {
    let cleanup;
    const initializeAndFetch = async () => {
      setComponentLoading(true);
      try {
        cleanup = await initializeSecureStorage();
        await fetchUsers();
      } finally {
        setComponentLoading(false);
      }
    };
    initializeAndFetch();

    return () => {
      if (cleanup) cleanup();
    };
  }, [fetchUsers]);

  const handleEditUser = (user) => {
    setEditingUser({
      uid: user.uid,
      firstName: user.displayName ? user.displayName.split(" ")[0] : "",
      lastName: user.displayName ? user.displayName.split(" ")[1] : "",
      email: user.email,
      password: "",
      confirmPassword: "",
    });
    setShowDialog(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingUser((prev) => ({ ...prev, [name]: value }));
    // if (name === "password") {
    //   setPasswordErrors(validatePassword(value));
    // }
  };

  //   const handleCreateUser = async () => {
  //     if (editingUser.firstName.trim() === '' || editingUser.lastName.trim() === '' || !editingUser.email) {
  //       toast.current.show({
  //         severity: "error",
  //         summary: "Error",
  //         detail: "First name, last name, and email are required",
  //       });
  //       return;
  //     }766766

  //     setLoading(true);
  //     try {
  //       const createNewAdmin = httpsCallable(functions, "createNewAdmin");
  //       const result = await createNewAdmin({
  //         email: editingUser.email,
  //         displayName: `${editingUser.firstName} ${editingUser.lastName}`,
  //       });

  //       // Send password reset email using EmailJS
  //       await sendPasswordResetEmail(editingUser.email, result.data.passwordResetLink, editingUser.firstName);

  //       toast.current.show({
  //         severity: "success",
  //         summary: "Success",
  //         detail: "New admin user created successfully. A password reset email has been sent.",
  //       });

  //       setShowDialog(false);
  //       localStorage.removeItem("adminUsers");
  //       localStorage.removeItem("adminUsersTimestamp");
  //       fetchUsers();
  //     } catch (error) {
  //       console.error("Error creating new admin user:", error);
  //       toast.current.show({
  //         severity: "error",
  //         summary: "Error",
  //         detail: `Failed to create new admin user: ${error.message}`,
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //  const sendPasswordResetEmail = async (resetLink) => {
  //    try {
  //      await emailjs.send(
  //        process.env.REACT_APP_EMAILJS_SERVICEID,
  //        "template_doto7zf",
  //        {
  //          to_email: editingUser.email,
  //          reset_link: resetLink,
  //          first_name: editingUser.firstName,
  //        },
  //        process.env.REACT_APP_EMAILJS_INIT
  //      );
  //      console.log(
  //        "Password reset email sent successfully to",
  //        editingUser.email
  //      );
  //    } catch (error) {
  //      console.error("Error sending password reset email:", error);
  //      throw new Error("Failed to send password reset email");
  //    }
  //  };

  // const resendVerificationEmail = async (userEmail) => {
  //   setLoading(true);
  //   try {
  //     const auth = getAuth();
  //     const user = auth.currentUser;

  //     if (!user) {
  //       throw new Error("No authenticated user found");
  //     }

  //     await sendEmailVerification(user);
  //     toast.current.show({
  //       severity: "success",
  //       summary: "Success",
  //       detail: "Verification email sent. Please check your inbox.",
  //     });
  //   } catch (error) {
  //     console.error("Error sending verification email:", error);
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: "Failed to send verification email: " + error.message,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const saveEditedUser = async () => {
    setOperationLoading(true);
    try {
      const editAdminUser = httpsCallable(functions, "editAdminUser");
      await editAdminUser({
        uid: editingUser.uid,
        displayName: `${editingUser.firstName} ${editingUser.lastName}`.trim(),
        email: editingUser.email,
      });

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: SUCCESS_MESSAGES.USER_UPDATED,
        life: 5000,
      });
      setShowDialog(false);
      clearAdminUsersCache();
      await fetchUsers(); // Refresh the user list
    } catch (error) {
      console.error("Error editing admin user:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Failed to edit admin user: ${error.message}`,
        life: 5000,
      });
    } finally {
      setOperationLoading(false);
    }
  };

  const deleteAdmin = (user) => {
    setUserToDelete(user);
    setShowConfirmDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleEditUser(rowData)}
          tooltip={isMobile ? null : "Edit User"}
          disabled={operationLoading || componentLoading}
        />
        {/* <Button
          icon="pi pi-envelope"
          className="p-button-rounded p-button-info p-mr-2"
          onClick={() => resendVerificationEmail(rowData.email)}
          tooltip={isMobile ? null : "Resend Verification Email"}
          disabled={rowData.emailVerified}
        /> */}
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => deleteAdmin(rowData)}
          tooltip={isMobile ? null : "Delete Admin User"}
          disabled={operationLoading || componentLoading}
        />
      </>
    );
  };

  const handleRefreshUsers = () => {
    clearAdminUsersCache();
    setPage(1); // Reset to first page
    fetchUsers();
  };

  const debouncedFetchUsers = debounce(handleRefreshUsers, 1000);

  const inviteUser = async (email, name, isAdmin) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: ERROR_MESSAGES.INVALID_EMAIL,
      });
      return;
    }

    if (name.length < 2 || name.length > 28) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: ERROR_MESSAGES.INVALID_NAME,
      });
      return;
    }

    setOperationLoading(true);
    try {
      const inviteUserFunction = httpsCallable(functions, "inviteUser");
      const result = await inviteUserFunction({ email, name, isAdmin });
      const signUpLink = result.data.url;
      
      await sendInvitationEmail(email, signUpLink, name, isAdmin);

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: SUCCESS_MESSAGES.INVITATION_SENT(isAdmin),
        life: 5000,
      });
      setShowInviteWorkerDialog(false);
      clearAdminUsersCache();
      setIsInvitingAdmin(false);
      setInviteWorkerEmail("");
      setInviteWorkerName("");
      await fetchUsers();
    } catch (error) {
      console.error("Error inviting user:", error);
      let errorMessage = ERROR_MESSAGES.INVITATION_FAILED;
      if (error.code === "unauthenticated") {
        errorMessage = ERROR_MESSAGES.UNAUTHENTICATED;
      } else if (error.code === "permission-denied") {
        errorMessage = ERROR_MESSAGES.PERMISSION_DENIED;
      } else if (error.code === "invalid-argument") {
        errorMessage = ERROR_MESSAGES.INVALID_ARGUMENT;
      } else if (error.code === "already-exists") {
        errorMessage = ERROR_MESSAGES.USER_EXISTS;
      }
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 5000,
      });
    } finally {
      setOperationLoading(false);
    }
  };

  const sendInvitationEmail = async (email, signUpLink, name, isAdmin) => {
    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICEID,
        "template_doto7zf", // You'll need to create a new template for invitations
        {
          to_email: email,
          sign_up_link: signUpLink,
          first_name: name.split(" ")[0],
          role: isAdmin ? "admin" : "worker",
        },
        process.env.REACT_APP_EMAILJS_INIT
      );
      console.log("Invitation email sent successfully to", email);
    } catch (error) {
      console.error("Error sending invitation email:", error);
      throw new Error("Failed to send invitation email");
    }
  };

  const handleCancelDialog = (dialogType) => {
    if (dialogType === 'invite') {
      setShowInviteWorkerDialog(false);
      setInviteWorkerEmail("");
      setInviteWorkerName("");
      setIsInvitingAdmin(false);
    } else if (dialogType === 'edit') {
      setShowDialog(false);
      setEditingUser("");
    }
    toast.current.show({
      severity: "info",
      summary: "Cancelled",
      detail: "Operation cancelled",
      life: 3000,
    });
  };

  const handleConfirmDelete = async () => {
    setOperationLoading(true);
    try {
      const deleteAdminFunction = httpsCallable(functions, "deleteAdmin");
      await deleteAdminFunction({ uid: userToDelete.uid });
      setUsers(users.filter((u) => u.uid !== userToDelete.uid));
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: SUCCESS_MESSAGES.USER_DELETED,
      });
      clearAdminUsersCache();
      await fetchUsers();
    } catch (error) {
      console.error("Error deleting admin user:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Failed to delete admin user: ${error.message}`,
      });
    } finally {
      setOperationLoading(false);
      setShowConfirmDialog(false);
      setUserToDelete(null);
    }
  };

  useDisableBackgroundScroll(showDialog);
  useDisableBackgroundScroll(showInviteWorkerDialog);
  useDisableBackgroundScroll(showConfirmDialog);

  return (
    <div className="admin-settings">
      <Toast ref={toast} position="top-right" />
      <h2>User Management</h2>
      <div className="p-d-flex p-jc-between p-mb-3">
        <Button
          label="Refresh Users"
          icon="pi pi-refresh"
          onClick={debouncedFetchUsers}
          disabled={componentLoading || operationLoading}
          loading={componentLoading}
          className="p-button-secondary"
        />
        <Button
          label="Invite User"
          icon="pi pi-user-plus"
          disabled={operationLoading || componentLoading}
          loading={operationLoading}
          onClick={() => setShowInviteWorkerDialog(true)}
          className="p-button-success"
        />
      </div>

      {isMobile ? (
        // Mobile version
        <div className="mobile-user-list">
          {componentLoading ? (
            <div className="loading-indicator">
              <ProgressSpinner />
              <p>Loading admin users...</p>
            </div>
          ) : users.length > 0 ? (
            users.map((user) => (
              <div key={user.uid} className="mobile-user-item">
                <div>{user.email}</div>
                <div>{user.displayName}</div>
                <div>{user.emailVerified ? "Verified" : "Not Verified"}</div>
                <div className="mobile-user-actions">
                  {actionBodyTemplate(user)}
                </div>
              </div>
            ))
          ) : (
            <p>No admin users found.</p>
          )}
        </div>
      ) : (
        // Desktop version
        <DataTable
          value={users}
          loading={componentLoading}
          responsiveLayout="scroll"
        >
          <Column field="email" header="Email" />
          <Column field="displayName" header="Name" />
          <Column
            field="emailVerified"
            header="Verified"
            body={(rowData) => (rowData.emailVerified ? "Yes" : "No")}
          />
          {!isMobile ? (
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: "8rem" }}
            />
          ) : (
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: "90vw" }}
            />
          )}
        </DataTable>
      )}

      <Dialog
        header="Edit User"
        visible={showDialog}
        style={{ width: "50vw" }}
        onHide={() => handleCancelDialog('edit')}
        footer={
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={() => handleCancelDialog('edit')}
              className="p-button-text"
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={saveEditedUser}
              autoFocus
              disabled={operationLoading || componentLoading}
              loading={operationLoading}
            />
          </div>
        }
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="firstName">First Name</label>
            <InputText
              id="firstName"
              name="firstName"
              value={editingUser?.firstName || ""}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="p-field">
            <label htmlFor="lastName">Last Name</label>
            <InputText
              id="lastName"
              name="lastName"
              value={editingUser?.lastName || ""}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="p-field">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              name="email"
              value={editingUser?.email || ""}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Invite User"
        visible={showInviteWorkerDialog}
        style={{ width: "50vw" }}
        onHide={() => handleCancelDialog('invite')}
        footer={
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={() => handleCancelDialog('invite')}
              className="p-button-text"
            />
            <Button
              label="Send Invitation"
              icon="pi pi-send"
              onClick={() => inviteUser(inviteWorkerEmail, inviteWorkerName, isInvitingAdmin)}
              autoFocus
              disabled={operationLoading || componentLoading}
              loading={operationLoading}
            />
          </div>
        }
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="userEmail">Email</label>
            <InputText
              id="userEmail"
              value={inviteWorkerEmail}
              onChange={(e) => setInviteWorkerEmail(e.target.value)}
              required
            />
          </div>
          <div className="p-field">
            <label htmlFor="userName">Name</label>
            <InputText
              id="userName"
              value={inviteWorkerName}
              onChange={(e) => setInviteWorkerName(e.target.value)}
              required
            />
          </div>
          <div className="p-field-checkbox">
            <Checkbox
              inputId="isAdmin"
              checked={isInvitingAdmin}
              onChange={(e) => setIsInvitingAdmin(e.checked)}
            />
            <label htmlFor="isAdmin">Invite as Admin</label>
          </div>
          <small className="p-mt-2">
            A sign-in link will be generated and sent to the user's email.
          </small>
        </div>
      </Dialog>

      <Dialog
        header="Confirm Deletion"
        visible={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        footer={
          <div>
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() => setShowConfirmDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={handleConfirmDelete}
              autoFocus
              disabled={operationLoading}
              loading={operationLoading}
            />
          </div>
        }
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {userToDelete && (
            <span>Are you sure you want to delete the admin user {userToDelete.email}?</span>
          )}
        </div>
      </Dialog>

      <Paginator
        first={(page - 1) * pageSize}
        rows={pageSize}
        totalRecords={totalUsers}
        onPageChange={(e) => {
          setPage(e.page + 1);
          setPageSize(e.rows);
        }}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </div>
  );
};

export default AdminSettings;