import { useEffect } from 'react';

const useDisableBackgroundScroll = (isOpen) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.classList.add('dialog-open');
    } else {
      document.body.style.overflow = 'unset';
      document.body.classList.remove('dialog-open');
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.classList.remove('dialog-open');
    };
  }, [isOpen]);
};

export default useDisableBackgroundScroll;
