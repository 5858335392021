import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as Yup from 'yup';

const FinishSignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [verifyingInvitation, setVerifyingInvitation] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const functions = getFunctions();
  const toast = useRef(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    console.log("FinishSignUp component mounted");
    const handleInvitationLink = async () => {
      console.log("Handling invitation link");
      console.log("Current URL:", window.location.href);
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      console.log("Token:", token);
      
      if (!token) {
        console.error("No token found in URL");
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Invalid invitation link',
        });
        setVerifyingInvitation(false);
        return;
      }

      try {
        console.log("Calling verifyInvitation function");
        const verifyInvitation = httpsCallable(functions, 'verifyInvitation');
        const result = await verifyInvitation({ token });
        console.log("verifyInvitation result:", result);
        setEmail(result.data.email);
        setIsAdmin(result.data.isAdmin);
      } catch (error) {
        console.error('Error verifying invitation:', error);
        let errorMessage = 'Invalid or expired invitation';
        if (error.message === 'Invitation has expired') {
          errorMessage = 'This invitation has expired. Please request a new invitation.';
        }
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMessage,
        });
      } finally {
        setVerifyingInvitation(false);
      }
    };

    handleInvitationLink();
  }, [auth, functions, location]);

  const passwordSchema = Yup.string()
    .min(8, "Password length should be at least 8 characters.")
    .max(15, "Password length should not exceed 15 characters.")
    .matches(/\d/, "Password should contain at least one digit (0-9).")
    .matches(/[a-z]/, "Password should contain at least one lowercase letter (a-z).")
    .matches(/[A-Z]/, "Password should contain at least one uppercase letter (A-Z).")
    .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, "Password should contain at least one special character (@, #, %, &, !, $, etc).")
    .test('no-spaces', "Password should not contain any space.", value => !/\s/.test(value))
    .required("Password is required");

  const validatePassword = (password) => {
    try {
      passwordSchema.validateSync(password);
      return [];
    } catch (error) {
      return error.errors;
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordErrors(validatePassword(newPassword));
  };

  const handleCompleteProfile = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passwords do not match",
      });
      setLoading(false);
      return;
    }

    try {
      const completeProfileFunction = httpsCallable(functions, "completeProfile");
      const result = await completeProfileFunction({
        email,
        password,
        isAdmin,
        additionalInfo: { /* Add any additional info here */ },
      });

      if (result.data.success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Profile completed successfully",
        });

        // Sign in the user
        await signInWithEmailAndPassword(auth, email, password);

        // Redirect based on user role
        if (isAdmin) {
          navigate("/admin/dashboard");
        } else {
          navigate("/worker/dashboard");
        }
      } else {
        throw new Error(result.data.error || "Failed to complete profile");
      }
    } catch (error) {
      console.error("Error completing profile:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to complete profile: " + error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const passwordHeader = <h6>Password Strength</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Password Requirements:</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase letter</li>
        <li>At least one uppercase letter</li>
        <li>At least one numeric digit</li>
        <li>At least one special character</li>
        <li>8-15 characters long</li>
        <li>No spaces</li>
      </ul>
    </React.Fragment>
  );

  if (verifyingInvitation) {
    return (
      <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '100vh' }}>
        <ProgressSpinner />
      </div>
    );
  }

//   if (!email) {
//     return (
//       <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '100vh' }}>
//         <h2>Invalid or expired invitation link</h2>
//       </div>
//     );
//   }

  return (
    <form onSubmit={handleCompleteProfile}>
      <Toast ref={toast} position="top-right" />
      <h2>Complete Your Profile</h2>
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="email">Email</label>
          <InputText id="email" type="email" value={email} disabled />
        </div>
        <div className="p-field">
          <label htmlFor="password">Set Password</label>
          <Password
            id="password"
            value={password}
            onChange={handlePasswordChange}
            header={passwordHeader}
            footer={passwordFooter}
            toggleMask
            required
          />
          {passwordErrors.map((error, index) => (
            <small key={index} className="p-error">{error}</small>
          ))}
        </div>
        <div className="p-field">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <Password
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            feedback={false}
            toggleMask
            required
          />
        </div>
        <Button type="submit" label="Complete Profile" loading={loading} />
      </div>
    </form>
  );
};

export default FinishSignUp;
