import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location]);

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const auth = getAuth();
      const actionCodeSettings = {
        url: `${window.location.origin}/admin`,
        handleCodeInApp: true,
      };
      
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      
      // Save the email locally to complete the sign-in process on the same device
      window.localStorage.setItem('emailForSignIn', email);
      
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Verification email sent. Please check your inbox.",
      });
    } catch (error) {
      console.error("Error sending verification email:", error);
      let errorMessage = "Failed to send verification email.";
      if (error.code === 'auth/invalid-email') {
        errorMessage = "The email address is invalid.";
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = "There is no user corresponding to this email address.";
      }
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleVerifyEmail} className="admin-login">
      <Toast ref={toast} position="top-right" />
      <div className="back-button-container">
        <h2>Verify Email</h2>
        <button
          type="button"
          className="back-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="verify-email">Email</label>
          <InputText
            id="verify-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
        </div>
        <Button
          type="submit"
          label="Send Verification Email"
          loading={loading}
        />
      </div>
    </form>
  );
};

export default VerifyEmail;
