export const ERROR_MESSAGES = {
  INVALID_EMAIL: "Please enter a valid email address",
  INVALID_NAME: "Name must be between 2 and 28 characters",
  INVITATION_FAILED: "Failed to invite user. Please try again.",
  UNAUTHENTICATED: "You must be logged in to invite users.",
  PERMISSION_DENIED: "You don't have permission to invite users.",
  INVALID_ARGUMENT: "Invalid email or name provided.",
  USER_EXISTS: "A user with this email already exists.",
};

export const SUCCESS_MESSAGES = {
  INVITATION_SENT: (isAdmin) => `${isAdmin ? 'Admin' : 'Worker'} invitation sent successfully.`,
  USER_UPDATED: "Admin user updated successfully.",
  USER_DELETED: "Admin user deleted successfully.",
};
